    
export function Bets({bets}) {

    return (
        <>
          <table className="table">
            <thead>
              <tr>
                <th>Player</th>
                <th>Bet</th>
                <th>Status</th>
                <th>Multiplier</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {bets.map((listItem, k) => {
                if (listItem.type.toString() === "w") {
                  return (
                    <tr key={listItem.id}>
                      <th scope="row">
                        {listItem.player.length > 10 ? listItem.player.slice(0, 4) + "..." + listItem.player.slice(-3) : listItem.player}

                        </th>
                      <td>{parseFloat(listItem.bet).toFixed(2)}</td>
                      <td><span className="badge badge-success">Won</span></td>
                      <td>x{parseFloat(listItem.multiplier).toFixed(2)}</td>
                      <td className="color-success">+{parseFloat(listItem.amount).toFixed(2)}</td>
                    </tr>
                  );
                } else if (listItem.type.toString() === "l") {
                  return (
                    <tr key={listItem.id}>
                      <th scope="row">
                        {listItem.player.length > 10 ? listItem.player.slice(0, 4) + "..." + listItem.player.slice(-3) : listItem.player}
                      </th>
                      <td>{parseFloat(listItem.bet).toFixed(2)}</td>
                      <td><span className="badge badge-danger">Lost</span></td>
                      <td>-</td>
                      <td className="color-danger">-{parseFloat(listItem.amount).toFixed(2)}</td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={listItem.id}>
                      <th scope="row">
                        {listItem.player.length > 10 ? listItem.player.slice(0, 4) + "..." + listItem.player.slice(-3) : listItem.player}
                      </th>
                      <td>{parseFloat(listItem.bet).toFixed(2)}</td>
                      <td><span className="badge badge-primary">...</span></td>
                      <td></td>
                      <td></td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </>
      )
      
}