import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { PrivateKey,Connection,Transaction,PublicKey,Keypair, SystemProgram,LAMPORTS_PER_SOL,clusterApiUrl,sendAndConfirmTransaction } from '@solana/web3.js'
import React,{ useEffect, useState, Component, useRef } from "react"
import initSqlJs from "sql.js";
import sqlWasm from "sql.js/dist/sql-wasm.wasm"
import Swal from 'sweetalert2'
import bs58 from 'bs58'
import { ToastContainer } from "react-toastr"

export const WalletConnector = () => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction } = useWallet();
    
    return (
    	<div>
            <WalletMultiButton />
        </div>
    )
}

export const WalletBalance  = ({setPlayer,updateBalance, updateDBalance}) => {
    const { connection } = useConnection();
    const { publicKey, sendTransaction, connected } = useWallet();
    const [balance, setBalance] = useState("0")
    // console.log("WalletBalance")
    // console.log("WalletBalance")
    // console.log("WalletBalanc",connected)
    // alert(connected)
    useEffect(() =>{
    // console.log("useEffect")

        if (connected){
            setPlayer(publicKey.toString())
            // console.log("is connected : ",publicKey.toString())
            // let lamports = 10000
            // connection.requestAirdrop(publicKey, lamports)
            const getWalletBalance = async () => {
                    try {
                    const walletBalance = await connection.getBalance(publicKey);
                    setBalance(walletBalance/LAMPORTS_PER_SOL)
                    console.log(`Wallet balance is ${walletBalance}`);
                    } catch (err) {
                    console.error(err);
                    }
                }
            getWalletBalance()
            updateDBalance("false")
            }
          },[connected,updateBalance])

    
    return (
          <div>{parseFloat(balance).toFixed(2)} ◎</div>
    )
}

export function PotBalance () {
  const { connection } = useConnection();
  const { publicKey, sendTransaction, connected } = useWallet();
  const [balance, setBalance] = useState("NaN")

  // alert(connected)
  useEffect(() =>{
      if (connected){
          // let lamports = 10000
          // connection.requestAirdrop(publicKey, lamports)
          const getWalletBalance = async () => {
                  try {
                  const walletBalance = await connection.getBalance(new PublicKey("F3TG8uDXDZWEDZeeiwchqJKKyTLqrzVx5K6kzNKfb5ux"));
                  setBalance(walletBalance/LAMPORTS_PER_SOL)
                  // console.log(`Pot balance is ${walletBalance}`);
                  } catch (err) {
                  console.error(err);
                  }
              }
          getWalletBalance()
          }
        },[connected])

  
  return (
      <b>
          {balance.toString()+" ◎"}
      </b>
  )
}
////////////////////
/// Balances Man ///
////////////////////
class BalancesMan extends Component {
  
  constructor(props) {
    super(props);
    this.state = {dbalance: 'NaN',wbalance: "NaN"};
    this.getdBalance = this.getdBalance.bind(this);
    this.getwBalance = this.getwBalance.bind(this);
  }
  getdBalance(){
    return this.state.dbalance;
  }
  getwBalance(){
    return this.state.wbalance;
  }
  setdBalance(val){
    this.setState({dbalance: val})
  }
  setwBalance(val){
    this.setState({wbalance: val})
  }
  render() {
    return (
      <></>
    );
  }
}

////////////////////
/// EoF Bala Man ///
////////////////////

////////////////////
/// Profil Manger///
export class ProfilForm extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "", nickname: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // Fetch the initial nickname when the component is mounted
    this.fetchData("GET");
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault(this.state.value);

    alert("Change: " + this.state.value);

    this.fetchData("POST", this.state.value);

    console.log("transfer succes");
  }

  fetchData(method, newNickname) {
    
    const publicKey = this.props.player; // Replace this with the actual public key
    const url = process.env.REACT_APP_API_URL+"/profileManager?w=" + publicKey;

    const options = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (method === "POST") {
      options.body = JSON.stringify({ nickname: newNickname, walletaddress: publicKey });
    }

    return fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        if (method === "GET") {
          // Set the initial nickname
          this.setState({ value: data["data"][0]["nickname"], nickname: data["data"][0]["nickname"] });
        } else {
          // Handle the response data after updating the nickname as needed
          console.log(data);
        }
      });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-body">
          <div className="form-group p-20">
            <h4 className="card-title">Nickname :</h4>
            <input
              type="text"
              placeholder="Type nickname"
              className="form-control"
              value={this.state.value}
              onChange={this.handleChange}
            />
          </div>
          <div className="form-actions">
            <input type="submit" className="btn btn-success" value="Change" />
          </div>
        </div>
      </form>
    );
  }
}

/// EoF ProfMang ///
////////////////////



/////////////////
// DEPOSIT SOL //
export class DepositForm extends Component {
  
  constructor(props) {
    super(props);
    this.state = {value: '',disabled: true,canDo: false};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    
  }
  handleSubmit(event) {
    alert('A deposit was submitted: ' + this.state.value);
    this.sendTx()
    TransferSOL(this.state.value).then(() => { console.log('transfered succe');}).then(() => {this.props.updateDBalance("true")})
    
    console.log('transfer success');

    DepositBalance()
    event.preventDefault(this.state.value);
  }

  handleChange(event) {    
    if(isNumber( event.target.value)){
      this.fetchData()
      if(this.state.canDo){
        this.setState({disabled: false})
      }else{
        this.setState({disabled: true})
      }
    }else{
      this.setState({disabled: true})
    }
    this.setState({value: event.target.value});  
  }
  
  sendTx () {
    var url = process.env.REACT_APP_API_URL+"/betManager"
    var data = {
        player: this.props.player,
        type: "d",
        amount: (this.state.value).toString(),
        currency: "sol",
        tx: 'notyet',
    }  
    fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache", 
        credentials: "same-origin", 
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        redirect: "follow", 
        referrer: "no-referrer", 
        body: JSON.stringify(data)
    }).then(function (response) {
        return response.json();
    });
    // .then(function (myJson) {
    //         console.log(myJson);
    //     });
  }
  fetchData() {
    
    const publicKey = this.props.player; // Replace this with the actual public key
    const url = process.env.REACT_APP_API_URL+"/canDo?walletaddress=" + publicKey+"&type=tx";

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
          this.setState({ canDo: data['can']});
      });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>  
        <div className="form-body">
          <div className="form-group p-20">
            <h4 className="card-title">Deposit :</h4>
            <input type="text" placeholder="Type an amount to deposit" className="form-control" value={this.state.value} onChange={this.handleChange} />        
          </div>
          <div className="form-actions">
            <input type="submit" className="btn btn-success" disabled={this.state.disabled} value="Deposit" />
          </div>
        </div>
      </form>
    );
  }
}

const getProvider = async () => {
  if ("solana" in window) {
    const provider = window.solana;
    if (provider.isPhantom) {
      console.log("Is Phantom installed?  ", provider.isPhantom);
      return provider;
    }
  } else {
    window.open("https://www.phantom.app/", "_blank");
  }
}

async function TransferSOL(solAmnt) {
  var provider = await getProvider();
  // I have hardcoded my secondary wallet address here. You can take this address either from user input or your DB or wherever
  var recieverWallet = new PublicKey("F3TG8uDXDZWEDZeeiwchqJKKyTLqrzVx5K6kzNKfb5ux");
  // Establishing connection
  var connection = new Connection(
    clusterApiUrl('testnet'),
  );
  // console.log("solAmnt")
  // console.log(solAmnt)
  var transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: provider.publicKey,
      toPubkey: recieverWallet,
      lamports: solAmnt*LAMPORTS_PER_SOL //Investing 1 SOL. Remember 1 Lamport = 10^-9 SOL.
    }),
  );

  // Setting the variables for the transaction
  transaction.feePayer = await provider.publicKey;
  let blockhashObj = await connection.getLatestBlockhash();
  transaction.recentBlockhash = await blockhashObj.blockhash;
  
  // Transaction constructor initialized successfully
  if(transaction) {
    console.log("Txn created successfully");
  }
  
  // Request creator to sign the transaction (allow the transaction)
  let signed = await provider.signTransaction(transaction);
  // The signature is generated
  let signature = await connection.sendRawTransaction(signed.serialize());
  // Confirm whether the transaction went through or not
  await connection.confirmTransaction(signature);
  /// Send txs data to API
  var url = process.env.REACT_APP_API_URL+"/betManager"
  var data = {
      player: provider.publicKey,
      type: "d",
      amount: (solAmnt).toString(),
      currency: "sol",
      tx: signature,
      update: 'true'
  }  
  fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache", 
      credentials: "same-origin", 
      headers: {
          "Content-Type": "application/json; charset=utf-8",
      },
      redirect: "follow", 
      referrer: "no-referrer", 
      body: JSON.stringify(data)
  }).then(function (response) {
      return response.json();
  });
  // .then(function (myJson) {
  //         console.log(myJson);
  //     });
      Swal.fire({
        title: 'Success',
        text: 'Deposit succes!',
        icon: 'success',
        confirmButtonText: 'Cool'
      })
  //Signature chhap diya idhar
  console.log("Signature: ", signature);
}
// EoF Deposit SOL //
/////////////////////

/////////////////
// WITHDRAW SOL //
export function WithdrawForm({updateDBalance}) {
  

  const { publicKey, sendTransaction, connected } = useWallet()
  const [depositBalance, setdepositbalance] = useState(0)
  const [canDo, setCanDo] = useState(false)
  const [canWithdraw, setCanWithdraw] = useState(false)
  const withdrawAmntRef = useRef(null)


  
  const handleChange = (e) => {
    fetch(process.env.REACT_APP_API_URL+"/depositbalance?w="+publicKey.toString()).then((response) => response.json()).then( (data) => setdepositbalance(data['data']["total"].toFixed(2))  )
    fetchData()
    // console.log("Can Do ",canDo)
    // console.log("Can Do ",canDo)
    // console.log("Can Do ",canDo)
    if(canDo && depositBalance != "" && parseFloat(depositBalance) >= parseFloat(withdrawAmntRef.current.value)){
      setCanWithdraw(true)
    }else{
      setCanWithdraw(false)
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    alert('A withdraw was submitted: ' + withdrawAmntRef.current.value);
    sendTx()
    WithdrawSOL(withdrawAmntRef.current.value).then(() => { console.log('withdraw succes')}).then(() => {updateDBalance("true")})
    setCanWithdraw(false)  
      
  }
  const sendTx = () => {
    var url = process.env.REACT_APP_API_URL+"/betManager"
    var data = {
        player: publicKey,
        type: "x",
        amount: (withdrawAmntRef.current.value).toString(),
        currency: "sol",
        tx: 'notyet',
    }  
    fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache", 
        credentials: "same-origin", 
        headers: {
            "Content-Type": "application/json; charset=utf-8",
        },
        redirect: "follow", 
        referrer: "no-referrer", 
        body: JSON.stringify(data)
    }).then(function (response) {
        return response.json();
    });
    // .then(function (myJson) {
    //         console.log(myJson);
    //     });
  }
  const fetchData = () => {
    
    const url = process.env.REACT_APP_API_URL+"/canDo?walletaddress=" + publicKey+"&type=tx";

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
         setCanDo(data['can']);
      });
  }
  return (
    <>
        <div className="form-body">
          <div className="form-group p-20">
            <h4 className="card-title">Withdraw :</h4>
            <input type="text" placeholder="Type an amount to withdraw" className="form-control"  ref={withdrawAmntRef} onChange={handleChange} />        

          </div>
          <div className="form-actions">
              {canWithdraw &&(
              <input type="submit" className="btn btn-success" value="Withdraw" onClick={handleSubmit} />
              )}
              {!canWithdraw &&(
              <input type="submit" className="btn btn-inverse" value="Withdraw" disabled />
              )}
          </div>
        </div>
    </>

  );
}


async function WithdrawSOL(solAmnt) {
  var provider = await getProvider();
  // I have hardcoded my secondary wallet address here. You can take this address either from user input or your DB or wherever
  var providerWallet = new PublicKey("F3TG8uDXDZWEDZeeiwchqJKKyTLqrzVx5K6kzNKfb5ux");
  // Establishing connection
  const privateKeyStr = bs58.decode("3czNMKtdsv3euGK85arSbajKGQewfM6dtAmyZ2CLhwMSEJFVEece6M4fWzbNbejpUTsRC4cAeVKzqBUudBnBdEGe")
  const keyPair = Keypair.fromSecretKey(privateKeyStr)
  
  var connection = new Connection(
    clusterApiUrl('testnet'),
  );
  // console.log("solAmnt")
  // console.log(solAmnt)
  var transaction = new Transaction().add(
    SystemProgram.transfer({
      fromPubkey: providerWallet,
      toPubkey: provider.publicKey,
      lamports: solAmnt*LAMPORTS_PER_SOL //Investing 1 SOL. Remember 1 Lamport = 10^-9 SOL.
    }),
  );

  // Setting the variables for the transaction
  transaction.feePayer = await providerWallet;
  let blockhashObj = await connection.getLatestBlockhash();
  transaction.recentBlockhash = await blockhashObj.blockhash;
  
  // Transaction constructor initialized successfully
  if(transaction) {
    console.log("Txn created successfully");
  }
  
  // Send transaction and confirm it (allow the transaction)
  let signature = await sendAndConfirmTransaction(connection,transaction,[keyPair]);
  // The signature is generated & confirmed
  console.log(signature)

  // Send txs data to API
  var url = process.env.REACT_APP_API_URL+"/betManager"
  var data = {
      player: provider.publicKey,
      type: "x",
      amount: (solAmnt).toString(),
      currency: "sol",
      tx: signature,
      update: "true",
    }  
  fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache", 
      credentials: "same-origin", 
      headers: {
          "Content-Type": "application/json; charset=utf-8",
      },
      redirect: "follow", 
      referrer: "no-referrer", 
      body: JSON.stringify(data)
  }).then(function (response) {
      return response.json();
  });
  // .then(function (myJson) {
  //         console.log(myJson);
  //     });
      
      Swal.fire({
        title: 'Success',
        text: 'Withdraw succes!\n',
        icon: 'success',
        confirmButtonText: 'Cool'
      })

  
  // console.log("Signature: ", signature);
}
// EoF WITHDRAW SOL //
/////////////////////

export function LoginInfos({updateNickname}) {
  const { connection } = useConnection();
  const [loginstate, setloginstate] = useState([]);
  const { publicKey, sendTransaction, connected } = useWallet();

  const fetchData = () => {
    return fetch(process.env.REACT_APP_API_URL+"/profileManager?w=" + publicKey.toString())
      .then((response) => response.json())
      .then((data) => {
        const nickname = data["data"][0]["nickname"];
        updateNickname(nickname)
        if (nickname.length > 10) {
          const shortenedNickname = nickname.slice(0, 4) + "..." + nickname.slice(-3);
          setloginstate(shortenedNickname);
          // console.log(shortenedNickname, loginstate);
        } else {
          setloginstate(nickname);
        }
      });
  };

  useEffect(() => {
    if (connected) {
      // console.log("Next fetchData");
      fetchData();
      // console.log(JSON.stringify(loginstate));
    }
  }, [connected]);

  return <>{loginstate}</>;
}

async function MakeLogin(username) {
  fetch(process.env.REACT_APP_API_URL+"/profileManager?w="+username).then(res => {
    // console.log(res.json());
    return res.json()
 })

  // const SQL = await initSqlJs({
  //   // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
  //   // You can omit locateFile completely when running in node
  //   autoSave: true,
  //   locateFile: file => sqlWasm
  // });
  // const db = new SQL.Database();
  // // let sqlstr = "CREATE TABLE users (walletaddress text, nickname char,level int, losttimes int,wintimes int, lastconnection datetime);";
  // // db.run(sqlstr)
  // // sqlstr = "INSERT INTO users values ('"+username.toString()+"','puppy',1,0,0,"+Date.now().toString()+");"
  // // db.run(sqlstr)
  // const stmt = db.prepare("SELECT * FROM users WHERE walletaddress=:aval ;");
  // const result = stmt.getAsObject({':aval' : username.toString()});
  // console.log(JSON.stringify(result))
  // let valtobind = [username.toString(),"puppy",1,0,0,Date.now()]
  // stmt.bind(valtobind)
}

export const DepositHistory = () => {
  const { connection } = useConnection();
  const { publicKey, sendTransaction, connected } = useWallet();
  const [txList, setTxList] = useState([])
  const destinationWallet = "F3TG8uDXDZWEDZeeiwchqJKKyTLqrzVx5K6kzNKfb5ux"

  const fetchData = () => {
    return fetch(process.env.REACT_APP_API_URL+"/depositbalance?w="+publicKey.toString()).then((response) => response.json())
    .then((data) => setTxList(data['data']["txs"]))
  }
  useEffect(() => {
    if (connected){
      // console.log("Next fetchData")
      fetchData()
    }
    },[connected])
  return (
    <><div className="row">
    <div className="col-lg-12">
        <div className="card">
            <div className="card-title">
                <h4>Transactions history </h4>

            </div>
            <div className="card-body">
                <div className="table-responsive">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Type</th>
                                <th>Status</th>
                                <th>Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {txList.map((listItem,k) =>  <tr key={k+1}><th scope="row">{k+1}</th><td>{listItem.type === "d" ? "Deposit" : listItem.type === "x" ? "Withdraw" : ""}</td><td><span className="badge badge-success">Finished</span></td><td>{new Date(listItem.created_at).toLocaleString()}</td><td>{listItem.amount} ◎</td></tr>)}
                          
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div></>
  )
}
export const DepositBalance = ({updateBalance,updateDBalance}) => {
  const [balance, setbalance] = useState(0);
  const { publicKey, sendTransaction, connected } = useWallet();
  const fetchData = () => {
    return fetch(process.env.REACT_APP_API_URL+"/depositbalance?w="+publicKey.toString()).then((response) => response.json())
    .then((data) => setbalance(data['data']["total"].toFixed(2)));
  }
  useEffect(() =>{
    if (connected){
      fetchData()
      // console.log("ITS CONNECTEEEEEEED")
      // console.log("ITS CONNECTEEEEEEED")
      // console.log("ITS CONNECTEEEEEEED")
      // console.log("ITS CONNECTEEEEEEED")
    }else{
      // console.log("ITS NOT CONNECTEEEEEEED")
      // console.log("ITS NOT CONNECTEEEEEEED")
      // console.log("ITS NOT CONNECTEEEEEEED")
      // console.log("ITS NOT CONNECTEEEEEEED")
      // updateDBalance("false")
    }
  },[connected,updateBalance])
  return (
    <>{balance}</>
  )
}
export function TotalGames ({playTimes,setPlayTimes}) {
  const [balance, setbalance] = useState(0);
  const { publicKey, sendTransaction, connected } = useWallet();
  const fetchData = () => {
    return fetch(process.env.REACT_APP_API_URL+"/depositbalance?w="+publicKey.toString()).then((response) => response.json())
    .then((data) => setbalance(data['data']["totalGames"]));
  }
  useEffect(() =>{
    if (connected){
      fetchData()
    }
    // console.log("WalletManager PlayTimes",playTimes)
    setPlayTimes("false")
    // console.log("WalletManager PlayTimes",playTimes)

  },[connected,playTimes])

  return (
    <>{balance}</>
  )
}

function isNumber(n) {

      return !isNaN(parseFloat(n)) && isFinite(n)
}
