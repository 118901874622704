import React, { Component } from 'react';
import socket from './socket';
import Avatar from 'react-avatar';

const OnlineUsersContext = React.createContext();

export class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            senderName: props.nickname,
            message: '',
            messages: [],
            oldMessages: [],
            onlineUsers: [],
            isConnected: socket.connected, // Initial socket connection status
        };
        this.messageListRef = React.createRef(); // Create a ref for the message list
    }

    componentDidMount() {
        // Listen for socket connection and disconnection events
        socket.on('connect', () => {
            this.setState({ isConnected: true });

            console.log('Connected to socket');
        });

        socket.on('disconnect', () => {
            this.setState({ isConnected: false });
            console.log('Disconnected from socket');
        });

        // Fetch old messages and scroll to the bottom
        fetch(process.env.REACT_APP_API_URL+'/messages')
            .then((response) => response.json())
            .then((data) => {
                this.setState({ oldMessages: data.data }, this.scrollToBottom);
            });

        // Listen for new messages
        socket.on('new message', (message) => {
            this.setState(
                { messages: [...this.state.messages, message] },
                this.scrollToBottom // Scroll when a new message arrives
            );
        });

        // Listen for online users
        socket.on('onlineUsers', (users) => {
            this.setState({ onlineUsers: users });
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // Scroll to bottom only if a new message is added
        if (prevState.messages.length !== this.state.messages.length) {
            this.scrollToBottom();
        }
        if (prevProps.nickname !== this.props.nickname) {
            this.setState({ senderName: this.props.nickname });
        }
    }

    scrollToBottom = () => {
        const messageList = this.messageListRef.current;
        if (messageList) {
            messageList.scrollTop = messageList.scrollHeight;
        }
    };

    handleSendMessage = () => {
        if (!this.state.isConnected) return; // Prevent sending if not connected

        socket.emit('new message', {
            sender: this.state.senderName,
            text: this.state.message,
            date: Date.now(),
        });
        this.setState({ message: '' });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            this.handleSendMessage();
        }
    };

    timestampToDate = (timestamp) => {
        return new Date(timestamp).toLocaleString();
    };

    render() {
        const { isConnected } = this.state;

        return (
            <>
                <OnlineUsersContext.Provider value={this.state.onlineUsers}>
                    <div className="row" id="chat">
                        <div className="col-lg-12">
                            <div className="card">
                                <div className="mt-4">
                                    <ul
                                        className="message-list"
                                        ref={this.messageListRef} // Attach the ref here
                                        style={{
                                            height: '300px',
                                            overflowY: 'scroll',
                                            overflowX: 'hidden',
                                        }}
                                    >
                                        {this.state.oldMessages.map((message) => (
                                            <li key={message.id}>
                                                <div className="row">
                                                    <div className="col-8 col-xl-3 d-flex align-items-top">
                                                        <span className="email-avatar">
                                                            <Avatar name={message.sender} size={30} round />
                                                        </span>
                                                        <span className="text-nowrap">
                                                            {message.sender.length > 10
                                                                ? `${message.sender.slice(0, 4)}...${message.sender.slice(
                                                                      -3
                                                                  )}`
                                                                : message.sender}
                                                        </span>
                                                    </div>
                                                    <div className="col-4 col-xl-2 text-right order-xl-3">
                                                        <div className="date mr-2">
                                                            {this.timestampToDate(message.date)}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-7">
                                                        <div className="subject">{message.message}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                        {this.state.messages.map((message, index) => (
                                            <li key={`new-${index}`}>
                                                <div className="row">
                                                    <div className="col-8 col-xl-3 d-flex align-items-top">
                                                        <span className="email-avatar">
                                                            <Avatar name={message.sender} size={30} round />
                                                        </span>
                                                        <span className="text-nowrap">
                                                            {message.sender.length > 10
                                                                ? `${message.sender.slice(0, 4)}...${message.sender.slice(
                                                                      -3
                                                                  )}`
                                                                : message.sender}
                                                        </span>
                                                    </div>
                                                    <div className="col-4 col-xl-2 text-right order-xl-3">
                                                        <div className="date mr-2">
                                                            {this.timestampToDate(message.date)}
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-7">
                                                        <div className="subject">{message.text}</div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <ul className="message-input" >
                                        <li>
                                            <div className="row">
                                                <div className="col-12 col-xl-12">
                                                    <div className="input-group input-group">
                                                        {this.state.senderName.toString() != "false" ? (<>
                                                            <input type="text" placeholder="Type something ..." name="Message" onKeyPress={this.handleKeyPress} onChange={(e) => this.setState({ message: e.target.value })} value={this.state.message} className="form-control " />
                                                            <span className="input-group-btn"><button className="btn btn-primary btn-group-right" type="submit" onClick={this.handleSendMessage} onKeyPress={this.handleKeyPress}><i className=" ti-control-play "></i></button></span>
                                                            </>
                                                        ) : (
                                                            <input type="text" placeholder="Connect & setup a nickname to start chatting" name="Message"  className="form-control " disabled />
                                                        )} 
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </OnlineUsersContext.Provider>
            </>
        );
    }
}

export { OnlineUsersContext };