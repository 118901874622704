import './App.css';
import { useMemo } from 'react';
import Playground from './Playground';
import PotManager from './PotManager';
import { Analytics } from "@vercel/analytics/react"

import { 
    DepositHistory, 
    WalletConnector, 
    PotBalance, 
    WalletBalance, 
    DepositForm,
    LoginInfos,
    DepositBalance , 
    TotalGames, 
    WithdrawForm,
    WalletConnected,
    ProfilForm
} from './WalletManager';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    GlowWalletAdapter,
    LedgerWalletAdapter,
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
    SolletExtensionWalletAdapter,
    SolletWalletAdapter,
    TorusWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { ToastContainer } from "react-toastr"
import Modal from 'react-modal';
import { useEffect, useRef, useState, useContext } from "react"
import './App.css';
import { Chat } from './Chat';
import socket from './socket';
import { useWallet } from '@solana/wallet-adapter-react';

// import the styles
require('@solana/wallet-adapter-react-ui/styles.css');

// for modal
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: "70%",
      zindex: "100",
      backgroundColor:"#0b0c21",
      border: "none"
    },
  }
Modal.setAppElement('#main-root');
Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0, 0.4)';
const App = () => {
    const [dbalance, setDBalance] = useState(0);
    const [player, setPlayer] = useState(false)
    const [nickname, setNickname] = useState(false)
    const [refreshBalances, setRefreshBalances] = useState("false")
    const [playTimes, setPlayTimes] = useState("false")

    const updatePlayer = (newPlayer) => {
        //console.log("trying to update player")
        setPlayer(newPlayer)
        //console.log("Player updated ",newPlayer)

    }
    //console.log("API URL:", process.env.REACT_APP_API_URL);
//console.log("Environment:", process.env.REACT_APP_ENV);
    let container;
    const updateDBalance = (newDBalance) => {
        setRefreshBalances(newDBalance);
      };
    const updatePlayTimes = (newGame) => {
        setPlayTimes(newGame);
    };
    const updateNickname = (newNickname) => {
        setNickname(newNickname);
    };
// for modal
    let subtitle;
    const [onlineUsers, setOnlineUsers] = useState(0)
	// you can use Mainnet, Devnet or Testnet here
    const [modalIsOpen, setIsOpen] = useState(false);
    // const [time, setTime] = useState('fetching')
    useEffect(() => {
        socket.on('onlineUsers', (users) => {
            setOnlineUsers(users);
        });
    }, []);  

    function openModal() {
        setIsOpen(true);
      }
    
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    // SOL CONFIG
    const solNetwork = WalletAdapterNetwork.Testnet;
    const endpoint = useMemo(() => clusterApiUrl(solNetwork), [solNetwork]);
    // initialise all the wallets you want to use
    const wallets = useMemo(
        () => [
            new PhantomWalletAdapter(),
            new GlowWalletAdapter(),
            new SlopeWalletAdapter(),
            new SolflareWalletAdapter({ solNetwork }),
            new TorusWalletAdapter(),
            new LedgerWalletAdapter(),
            new SolletExtensionWalletAdapter(),
            new SolletWalletAdapter(),
        ],
        [solNetwork]
    );
    const i = getRandomFloat(1,10,2);
    function getRandomFloat(min, max, decimals) {
    const str = (Math.random() * (max - min) + min).toFixed(decimals);
    
      return parseFloat(str);
    }
    
    return (
        <ConnectionProvider endpoint={endpoint}>
            <WalletProvider wallets={wallets} >
                <WalletModalProvider>
                <div className="App">
                  <div id="main-wrapper">
                        {/* header header  */}
                        <div className="header">
                            <nav className="navbar top-navbar navbar-expand-md navbar-light">
                                {/* Logo */}
                                <div className="navbar-header">
                                    <a className="navbar-brand" href="index.html">
                                        {/* Logo icon */}
                                        <b><img src="images/logo.png" alt="homepage" className="dark-logo" /></b>
                                        {/*End Logo icon */}
                                        {/* Logo text */}
                                        {/* <span>xCrash</span> */}
                                        <span><img src="images/logo-text.png" alt="homepage" className="dark-logo" /></span>
                                    </a>
                                </div>
                                {/* End Logo */}
                                <div className="navbar-collapse">
                                    {/* toggle and nav items */}
                                    <ul className="navbar-nav mr-auto mt-md-0">
                                        {/* This is  */}
                                        <li className="nav-item"> <a className="nav-link toggle-nav hidden-md-up text-muted  " href="javascript:void(0)"><i className="mdi mdi-menu"></i></a> </li>
                                        <li className="nav-item m-l-10"> <a className="nav-link sidebartoggle hidden-sm-down text-muted  " href="javascript:void(0)"><i className="ti-menu"></i></a> </li>

                                        {/* End Messages */}
                                    </ul>
                                    {/* User profile and search */}
                                    <ul className="navbar-nav my-lg-0">
                                        {/* Comment */}
                                        {player.toString() !== "false" &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link dropdown-toggle text-muted text-muted  " href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="fa fa-bell"></i>
                                                <div className="notify"> <span className="heartbit"></span> <span className="point"></span> </div>
                                            </a>
                                            <div className="dropdown-menu dropdown-menu-right mailbox animated slideInRight">
                                                <ul>
                                                    <li>
                                                        <div className="drop-title">Notifications</div>
                                                    </li>
                                                    <li>
                                                        <div className="header-notify">
                                                            {/* Message */}
                                                            <a href="#">
                                                                <i className="cc BTC m-r-10 f-s-40" title="BTC"></i>
                                                                <div className="notification-contnet">
                                                                    <h5>All Transaction BTC</h5> <span className="mail-desc">Just see the my new admin!</span> 
                                                                </div>
                                                            </a>
                                                            {/* Message */}
                                                            <a href="#">
                                                                <i className="cc LTC m-r-10 f-s-40" title="BTC"></i>
                                                                <div className="notification-contnet">
                                                                    <h5>This is LTC coin</h5> <span className="mail-desc">Just a reminder that you have event</span>
                                                                </div>
                                                            </a>
                                                            {/* Message */}
                                                            <a href="#">
                                                                <i className="cc DASH m-r-10 f-s-40" title="BTC"></i>
                                                                <div className="notification-contnet">
                                                                    <h5>This is DASH coin</h5> <span className="mail-desc">You can customize this template as you want</span>
                                                                </div>
                                                            </a>
                                                            {/* Message */}
                                                            <a href="#">
                                                                <i className="cc XRP m-r-10 f-s-40" title="BTC"></i>
                                                                <div className="notification-contnet">
                                                                    <h5>This is LTC coin</h5> <span className="mail-desc">Just see the my admin!</span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a className="nav-link text-center" href="javascript:void(0);"> Check all notifications <i className="fa fa-angle-right"></i> </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                        }
                                        {/* End Comment */}
                                        {/* Messages */}
                                        {player.toString() !== "false" &&
                                        <li className="nav-item dropdown">
                                            <a className="nav-link  text-muted  " href="#chat" id="2" > <i className="fa fa-envelope"></i>
                                                <div className="notify"> <span className="heartbit"></span> <span className="point"></span> </div>
                                            </a>
                                        </li>
                                        }
                                        {/* End Messages */}
                                        {/* Profile */}
                                        {player.toString() !== "false" &&
                                        <li className="nav-item">
                                            <a className="nav-link text-muted  " href="#" onClick={openModal} aria-haspopup="true" aria-expanded="false"><i className="fa fa-user"></i>  <LoginInfos updateNickname={updateNickname}/></a>
                                        </li>
                                        }

                                        <li className="nav-item dropdown">
                                        <WalletConnector />
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                        {/* End header header */}
                        {/* Left Sidebar  */}
                        <div className="left-sidebar">
                            {/* Sidebar scroll*/}
                            <div className="scroll-sidebar">
                                {/* Sidebar navigation*/}
                                <nav className="sidebar-nav">
                                    <ul id="sidebar-menu">
                                        <li className="nav-devider"></li>
                                        <li className="nav-label">Home</li>
                                        <li> <a className="has-arrow  " href="#" aria-expanded="false"><i className="fa fa-tachometer"></i><span className="hide-menu">Playground <span className="label label-rouded label-primary pull-right">6</span></span></a>
                                            
                                        </li>
                                        
                                    </ul>
                                </nav>
                                {/* End Sidebar navigation */}
                            </div>
                            {/* End Sidebar scroll*/}
                        </div>
                        {/* End Left Sidebar  */}
                        {/* Page wrapper  */}
                        <div className="page-wrapper">
                        
                            {/* Bread crumb */}
                            <div className="row page-titles">
                                <div className="col-md-5 align-self-center">
                                    <h3 className="text-primary">Playground</h3> </div>
                                <div className="col-md-7 align-self-center">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="javascript:void(0)">Home</a></li>
                                        <li className="breadcrumb-item active">Playground</li>
                                    </ol>
                                </div>
                            </div>
                            {/* End Bread crumb */}
                            {/* Container fluid  */}
                            <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="alert-banner">
                                        <div className="alert alert-warning text-center" role="alert">
                                            <strong>Notice:</strong> This application is running on the <span className="badge badge-info p-2">Testnet</span>. Use testnet wallets and assets only.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card p-30">
                                        <div className="media">
                                            <div className="media-left meida media-middle">
                                                <span><i className="ti-money f-s-40 color-primary"></i></span>
                                            </div>
                                            <div className="media-body text-right">
                                                <h4><b><DepositBalance updateBalance={refreshBalances} updateDBalance={updateDBalance}/> ◎</b></h4>
                                                <p className="m-b-0">Deposit Balance</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card p-30">
                                        <div className="media">
                                            <div className="media-left meida media-middle">
                                                <span><i className="ti-wallet f-s-40 color-success"></i></span>
                                            </div>
                                            <div className="media-body text-right">
                                                <h4><b><WalletBalance setPlayer={updatePlayer} updateBalance={refreshBalances} updateDBalance={updateDBalance}/></b></h4>
                                                <p className="m-b-0">Wallet Balance</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card p-30">
                                        <div className="media">
                                            <div className="media-left meida media-middle">
                                                <span><i className="ti-timer f-s-40 color-warning"></i></span>
                                            </div>
                                            <div className="media-body text-right">
                                                <h4><b><TotalGames setPlayTimes={updatePlayTimes} playTimes={playTimes}/></b></h4>
                                                <p className="m-b-0">Play Times</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="card p-30">
                                        <div className="media">
                                            <div className="media-left meida media-middle">
                                                <span><i className="ti-user f-s-40 color-danger"></i></span>
                                            </div>
                                            <div className="media-body text-right">
                                                <h4><b>{onlineUsers.length}</b></h4>
                                                <p className="m-b-0">Online Players</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            

                            <Playground seconds={10} updateDBalance={updateDBalance} setPlayTimes={updatePlayTimes}   />
                                {/* Chat section */}
                            <Chat player={player} nickname={nickname}/>
                                {/* EoFChat section */}

                            <Modal
                                isOpen={modalIsOpen}
                                onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >
                            <ul className="nav nav-tabs customtab" role="tablist">
                                <li className="nav-item"> <a className="nav-link active" data-toggle="tab" href="#messages3" role="tab"><span className="hidden-sm-up"><i className="ti-wallet"></i></span> <span className="hidden-xs-down">Deposit</span></a> </li>
                                <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#home3" role="tab"><span className="hidden-sm-up"><i className="ti-money"></i></span> <span className="hidden-xs-down">Withdraw</span> </a> </li>
                                <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#profile3" role="tab"><span className="hidden-sm-up"><i className="ti-user"></i></span> <span className="hidden-xs-down">Profile</span></a> </li>
                                <li className="nav-item"> <a className="nav-link" data-toggle="tab" href="#history3" role="tab"><span className="hidden-sm-up"><i className="ti-user"></i></span> <span className="hidden-xs-down">History</span></a> </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane  w-100" id="home3" role="tabpanel">
                                <WithdrawForm updateDBalance={updateDBalance}/>
                                </div>
                                <div className="tab-pane   w-100" id="profile3" role="tabpanel">
                                <ProfilForm player={player} />
                                </div>
                                <div className="tab-pane active w-100" id="messages3" role="tabpanel">
                                <DepositForm updateDBalance={updateDBalance} player={player} />

                                </div>
                                <div className="tab-pane   w-100" id="history3" role="tabpanel">
                                <DepositHistory />
                                </div>
                            </div>
                            </Modal> 
                            {/* <h1>Pot has : <PotBalance /> </h1> */}
                            

                                
                                {/* End PAge Content */}
                            </div>
                            {/* End Container fluid  */}
                            {/* footer */}
                            <footer className="footer"> © 2025 xCrash All Right Reserved.</footer>
                            {/* End footer */}
                        </div>
                        {/* End Page wrapper  */}
                    </div>
                    <Analytics />

                </div>
                </WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
}

export default App;
