import { useEffect, useRef, useState } from "react"
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import {Bets} from "./Bets"
import socket from './socket';
import  BetInputCard  from './playground/BetInputCard'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  )


  
export default function Playground({seconds,updateDBalance,setPlayTimes}) {
    //
    const [countdown, setCountDown] = useState(0)
    const [waittime, setwaittime] = useState(0)
    const [multiplier, setmultiplier] = useState(0)
    const [roundId, setRoundId] = useState(0)
    const [prevmultiplier, setprevmultiplier] = useState(0)
    const { connection } = useConnection();
    const { publicKey, sendTransaction, connected } = useWallet();
    const [gameMsg, setGameMsg] = useState("")
    const [playAmount, setPlayAmount] = useState(0)
    const [chartmx, setchartmx] = useState(4)
    const [cashedOut, setCashedOut] = useState(false)
    const [canPlay, setCanPlay] = useState(false)
    const [bets, setBets] = useState([])

    const [isTimeToPlay, setIsTimeToPlay] = useState(false)
    const [isBetting, setIsBetting] = useState(false)
    const [betBtn, setbetBtn] = useState(true)
    const [validBetAmount, setvalidBetAmount] = useState(false)
    const [insuBetAmount, setinsuBetAmount] = useState(false)
    const [invalidBet, setInvalidBet] = useState(false)
    const timerId = useRef()
    const hasCrashBeenHandled = useRef(false);
    const multiplexerId = useRef()
    const betAmountRef = useRef(null);
    const [depositBalance, setdepositbalance] = useState(0)
    const startTime = useRef(null);

    const chartoptions = {
    responsive: true,
    maintainAspectRatio: false, // Allows the chart to fill the parent container
    plugins: {
        legend: {
        display: false,
        },
        subtitle: {
        display: true,
        text: 'Multiplier',
        align: 'center',
        },
        
    },
    scales: {
        y:
          {
            min: 0,
            suggestedMax: 2,
            stepSize: 5,
            ticks: {
              font: {
                size: 15,
              },
                color: '#fff', // Tick label color set to white
                callback: (label) => `x${label}`, // Custom format for the label
            },
            // grid: {
            //   color: 'rgba(255, 255, 255, 0.4)', // Optional: Light white grid lines
            // },
          },
        x:
          {
            // min: 0,
            // suggestedMax: 5,
            // stepSize: 5,
            ticks: {
                display: false,
                gridLines: {
                    color: "white"
                  }
           },
          },
      },
    };

    let middle = chartmx/2
    let middle3 = chartmx/5
    const chartlabels1 = {0:0,middle3:middle3,middle:middle,chartmx:chartmx};
    const chartlabels = [0,1,2,3,4,5,6,7,8,9,10];
  
    
    const data = {
    chartlabels,
    datasets: [
        {
        label:" waittime.toString()",
        data: chartlabels1,
        fill: true,
        borderColor: '#5c4ac7',
        backgroundColor: '#5c4ac7',
        radius: 0,
        tension: 0.4,
        borderWidth: 7.5,
        borderCapStyle: 'round', // Rounded ends of the line
        borderJoinStyle: 'round', // Rounded corners at line joins
        },
    ],
    };
  


    useEffect(() => {
        socket.on('timer', (newTimer) => {
            setCountDown(newTimer);

        });
        
        socket.on('new bet', (bet) => {
            setBets(prevBets => {
              const updatedBets = [...prevBets, bet];
              const uniqueBetsMap = new Map();
              updatedBets.forEach(bet => {
                if (bet.type !== 0 || !uniqueBetsMap.has(bet.player)) {
                  uniqueBetsMap.set(bet.player, bet);
                }
              });
              const uniqueBets = Array.from(uniqueBetsMap.values());
              return uniqueBets;
            });
          });
        socket.on('round-id', (newRound) => {
            setRoundId(newRound);
        });
        
        
        socket.on('multiplier', (newMultiplier) => {
            // Use functional form to ensure 'oldVal' is the last known multiplier
            setmultiplier((oldVal) => {
              if (newMultiplier == -1) {
                // Store the final real multiplier
                setprevmultiplier(oldVal);
                // Then set local multiplier to 0
                return 0;
              } else {
                // Otherwise, just update multiplier normally
                return newMultiplier;
              }
            });
          });
    }, []);
  
    useEffect(() => {


        multiplexerId.current = setInterval(() => {
            setwaittime(prev => prev + 0.01)
        }, 10)
        setchartmx(multiplier)
        

        return () => clearInterval(multiplexerId.current)
    },[multiplier])
    
    
    useEffect(() => {
        if (multiplier <= 0 && !hasCrashBeenHandled.current) {
          hasCrashBeenHandled.current = true; // Mark as handled
    
          clearInterval(multiplexerId.current);
          
          // If crashed while still playing
          if (!cashedOut && isTimeToPlay && isBetting && connected) {
            setbetBtn(false);
            setGameMsg("GAME CRASHED !!!! You lose");
            setIsBetting(false);
            updateDBalance("true");
    
            const url = process.env.REACT_APP_API_URL+"/betManager";
            const data = {
              player: publicKey,
              type: "l",
              amount: playAmount.toString(),
              bet: playAmount.toString(),
              round_id: roundId,
              multiplier: 0,
              currency: "sol",
              update: "true",
            };
            fetch(url, {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify(data),
            }).then(response => response.json())
              .then(myJson => {
                // Handle response if needed
              });
          }
    
          setCashedOut(false);
          setIsTimeToPlay(false);
        }
    
        // Reset the crash handler when multiplier resets above 0
        if (multiplier > 0 && hasCrashBeenHandled.current) {
          hasCrashBeenHandled.current = false;
        }
      }, [multiplier, cashedOut, isTimeToPlay, isBetting, connected, playAmount, publicKey, roundId, updateDBalance]);
      
      // 2) If countdown <= 0
      useEffect(() => {
        if (countdown <= 0) {
          if (!isTimeToPlay) {
            setIsTimeToPlay(true);
          }
          clearInterval(timerId.current);
        }
      }, [countdown, isTimeToPlay]);
      
      // 3) If countdown == 9
      useEffect(() => {
        if (countdown === 9) {
          setwaittime(0);
          setInvalidBet(false);
          setchartmx(0);
          setBets([]);
          setIsTimeToPlay(true);
        }
      }, [countdown]);
      const handlePlay = async (ev) => {
        ev.preventDefault();
        ev.currentTarget.disabled = true;
        setbetBtn(true);
    
        //console.log("PLAY CLICKED");
    
        setPlayTimes("true");
    
        // Fetch canPlay status and handle it immediately
        const canPlayResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/canDo?walletaddress=${publicKey.toString()}&round_id=${roundId}&type=play`
        ).then((response) => response.json());
    
        const canPlayValue = canPlayResponse['can'];
        setCanPlay(canPlayValue); // Update state for reactivity
    
        if (canPlayValue.toString() === "true") {
            //console.log("Player can play. Proceeding with play transaction.");
    
            // Set play amount and prepare data for the play transaction
            const playAmount = betAmountRef.current.value;
            setPlayAmount(playAmount);
    
            const data = {
                player: publicKey,
                bet: playAmount.toString(),
                round_id: roundId,
                currency: "sol",
            };
    
            try {
                const response = await fetch(process.env.REACT_APP_API_URL+"/betManager", {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    redirect: "follow",
                    referrer: "no-referrer",
                    body: JSON.stringify(data),
                });
    
                const result = await response.json();
                //console.log("Play transaction result:", result);
            } catch (error) {
                console.error("Error sending play transaction:", error);
            }
    
            setIsBetting(true);
            setInvalidBet(false);
        } else {
            // Handle case where the player cannot play
            setInvalidBet(true);
            setbetBtn(false);
            //console.log("Player cannot play.");
        }
    };
    const handleBetAmnt = (ev) => {
        ev.preventDefault();
        const playingAmnt = ev.target.value
        fetch(process.env.REACT_APP_API_URL+"/depositbalance?w="+publicKey.toString()).then((response) => response.json()).then( (data) => setdepositbalance(data['data']["total"].toFixed(2))  )
        setvalidBetAmount(isNumber(playingAmnt))

        if(isNumber(playingAmnt) && parseFloat(playingAmnt) <= depositBalance){
            setbetBtn(false)
            setinsuBetAmount(false)
            setvalidBetAmount(false)
            
        }else 
            if(isNumber(playingAmnt) && parseFloat(playingAmnt) > depositBalance){
                setbetBtn(true)
                setinsuBetAmount(true)
                setvalidBetAmount(false)
            }
            else 
                if(playingAmnt == ""){
                    setbetBtn(true)
                    setvalidBetAmount(false)
                    setinsuBetAmount(false)
                    }
                    else 
                        if(!isNumber(playingAmnt)){
                            setbetBtn(true)
                            setvalidBetAmount(true)
                            setinsuBetAmount(false)
                        }

    }
    /// IF CASHED OUT
    const handleCashout = (e) => {
        e.preventDefault();
        if(connected && isBetting){
            setCashedOut(true)
            setIsBetting(false)
            setbetBtn(false)
            updateDBalance("true")
            setGameMsg("Cashed OUT! x"+multiplier.toFixed(2).toString()+" total :"+(playAmount *multiplier).toFixed(2).toString())

            e.currentTarget.disabled = true;
            var url = process.env.REACT_APP_API_URL+"/betManager"
            var data = {
                player: publicKey,
                type: "w",
                amount: (playAmount *multiplier).toString(),
                bet: (playAmount).toString(),
                round_id: roundId,
                multiplier: multiplier,
                currency: "sol",
                update: "true",
            }  
            fetch(url, {
                method: "POST",
                mode: "cors",
                cache: "no-cache", 
                credentials: "same-origin", 
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                redirect: "follow", 
                referrer: "no-referrer", 
                body: JSON.stringify(data)
            }).then(function (response) {
                return response.json();
            }).then(function (myJson) {
                    ////console.log(myJson);
                });
        }
    }
    if(connected){
        if( multiplier <= 0  ){

            return(
            <>
            <div className="row">
                <div className="col-lg-5">
                    <div className="card">
                        <div className="stat-widget-two">
                            <div className="stat-content chart-container">
                            <Line options={chartoptions} data={data} />
                                {waittime == 0 ? (
                                    <div className="stat-digit text-danger absolute-middle"></div>


                                ) : (
                                    <div className="stat-digit text-danger absolute-middle">Game Crashed @ x{prevmultiplier.toFixed(2)}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card">
                        <div className="button-list">
                            <input
                                ref={betAmountRef}
                                type="text"
                                id="betAmnout"
                                name="betAmount"
                                className="form-control"
                                placeholder="Place a bet"
                                onChange={handleBetAmnt}
                            />
                            
                            <button type="button" onClick={handlePlay} disabled={betBtn} className="btn btn-primary btn-block m-b-10">Place Bet</button>
                            
                            {insuBetAmount &&(
                                <div className="alert alert-danger" >
                                    Insufficient funds, deposit to bet that amount.
                                </div>
                            )}
                            {invalidBet &&(
                                <div className="alert alert-danger" >
                                    Only one bet per wallet allowed
                                </div>
                            )}
                            {validBetAmount &&(
                                <div className="alert alert-info" >
                                    Please enter a valid amount.
                                </div>
                            )}
                        </div>
                        <div className="stat-widget-two">
                            <div className="progress">
                                <div className="progress-bar progress-bar-primary" style={{width:((seconds-countdown)*10).toString()+"%"}} role="progressbar" aria-valuenow={countdown} aria-valuemin="0" aria-valuemax={seconds}></div>
                            </div>
                            <div className="stat-content">
                                <div className="stat-text">Next Round in </div>
                                <div className="stat-digit text-primary">{countdown}s</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div class="card-title">
                            <h4>Playing Now </h4>

                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <Bets bets={bets}/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            </>
            )
        }else{
            if(isBetting){
                return(<>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="stat-widget-two">
                                <div className="stat-content chart-container">
                                <Line options={chartoptions} data={data} />
                                    <div className="stat-digit text-primary absolute-middle">x{multiplier.toFixed(2)}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="button-list">
                                <input
                                ref={betAmountRef}
                                    type="text"
                                    id="betAmnout"
                                    name="betAmount"
                                    className="form-control"
                                    placeholder="Place a bet"
                                    onChange={handleBetAmnt}
                                />
                                <button type="button" onClick={handleCashout} className="btn btn-primary btn-block m-b-10">Cash OUT</button>
                                {insuBetAmount &&(
                                <div className="alert alert-danger" >
                                    Insufficient funds, deposit to bet that amount.
                                </div>
                                )}
                                {invalidBet &&(
                                    <div className="alert alert-danger" >
                                        Only one bet per wallet allowed
                                    </div>
                                )}
                                {validBetAmount &&(
                                    <div className="alert alert-info" >
                                        Please enter a valid amount.
                                    </div>
                                )}
                            </div>
                            <div className="stat-widget-two">
                                <div className="progress">
                                    <div className="progress-bar progress-bar-primary w-100" role="progressbar" aria-valuenow={countdown} aria-valuemin="0" aria-valuemax="10"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div class="card-title">
                                <h4>Playing Now </h4>

                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <Bets bets={bets}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>
                )
            }else{
                return(<>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="stat-widget-two">
                                <div className="stat-content chart-container">
                                <Line options={chartoptions} data={data} />
                                    <div className="stat-digit text-primary absolute-middle">x{multiplier.toFixed(2)}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="button-list">
                                <input
                                ref={betAmountRef}
                                    type="text"
                                    id="betAmnout"
                                    name="betAmount"
                                    className="form-control"
                                    placeholder="Place a bet"
                                    onChange={handleBetAmnt}

                                />
                                {cashedOut ? (
                                    <button type="button"  disabled className="btn btn-success btn-block m-b-10">Cashed Out</button>

                                ) : (
                                    <button type="button"  disabled className="btn btn-primary btn-block m-b-10">Game in progress</button>

                                )}
                                {insuBetAmount &&(
                                <div className="alert alert-danger" >
                                    Insufficient funds, deposit to bet that amount.
                                </div>
                                )}
                                {invalidBet &&(
                                    <div className="alert alert-danger" >
                                        Only one bet per wallet allowed
                                    </div>
                                )}
                                {validBetAmount &&(
                                    <div className="alert alert-info" >
                                        Please enter a valid amount.
                                    </div>
                                )}
                            </div>
                            <div className="stat-widget-two">
                                <div className="progress">
                                    <div className="progress-bar progress-bar-primary w-100" role="progressbar" aria-valuenow={countdown} aria-valuemin="0" aria-valuemax="10"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div class="card-title">
                                <h4>Playing Now </h4>

                            </div> 
                            <div class="card-body">
                                <div class="table-responsive">
                                    <Bets bets={bets}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div></>
                )
            }
        }
    }else{
        if(multiplier <= 0 ){

            return(
            <>
            <div className="row">
                <div className="col-lg-5">
                    <div className="card">
                        <div className="stat-widget-two">
                            <div className="stat-content chart-container">
                            <Line options={chartoptions} data={data} />
                                {waittime == 0 ? (
                                    <div className="stat-digit text-danger absolute-middle"></div>


                                ) : (
                                    <div className="stat-digit text-danger absolute-middle">Game Crashed @ x{prevmultiplier.toFixed(2)}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card">
                        <div className="button-list">
                            <input
                                ref={betAmountRef}
                                type="text"
                                id="betAmnout"
                                name="betAmount"
                                className="form-control"
                                placeholder="Place a bet"
                                onChange={handleBetAmnt}
                            />
                            <button type="button" disabled className="btn btn-primary btn-block m-b-10">Place Bet</button>


                            {insuBetAmount &&(
                                <div className="alert alert-danger" >
                                    Insufficient funds, deposit to bet that amount.
                                </div>
                            )}
                            {invalidBet &&(
                                <div className="alert alert-danger" >
                                    Only one bet per wallet allowed
                                </div>
                            )}
                            {validBetAmount &&(
                                <div className="alert alert-info" >
                                    Please enter a valid amount.
                                </div>
                            )}
                        </div>
                        <div className="stat-widget-two">
                            <div className="progress">
                                <div className="progress-bar progress-bar-primary" style={{width:((seconds-countdown)*10).toString()+"%"}} role="progressbar" aria-valuenow={countdown} aria-valuemin="0" aria-valuemax={seconds}></div>
                            </div>
                            <div className="stat-content">
                                <div className="stat-text">Next Round in </div>
                                <div className="stat-digit text-primary">{countdown}s</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card">
                        <div class="card-title">
                            <h4>Playing Now </h4>

                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <Bets bets={bets}/>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
            )
        }else{
            return(
            <>
                <div className="row">
                    <div className="col-lg-5">
                        <div className="card">
                            <div className="stat-widget-two">
                                <div className="stat-content chart-container">
                                <Line options={chartoptions} data={data} />
                                    <div className="stat-digit text-primary absolute-middle">x{multiplier.toFixed(2)}</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="card">
                            <div className="button-list">
                                <input
                                ref={betAmountRef}
                                    type="text"
                                    id="betAmnout"
                                    name="betAmount"
                                    className="form-control"
                                    placeholder="Place a bet"
                                    onChange={handleBetAmnt}

                                />
                                <button type="button"  disabled className="btn btn-primary btn-block m-b-10">Game in progress</button>
                                
                                {insuBetAmount &&(
                                <div className="alert alert-danger" >
                                    Insufficient funds, deposit to bet that amount.
                                </div>
                                )}
                                {invalidBet &&(
                                    <div className="alert alert-danger" >
                                        Only one bet per wallet allowed
                                    </div>
                                )}
                                {validBetAmount &&(
                                    <div className="alert alert-info" >
                                        Please enter a valid amount.
                                    </div>
                                )}
                            </div>
                            <div className="stat-widget-two">
                                <div className="progress">
                                    <div className="progress-bar progress-bar-primary w-100" role="progressbar" aria-valuenow={countdown} aria-valuemin="0" aria-valuemax="10"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="card">
                            <div class="card-title">
                                <h4>Playing Now </h4>

                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <Bets bets={bets}/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div></>)
        }
    }

    function isNumber(n) {
        if(n=="")
        {
            return true}
        else
        {
            return !isNaN(parseFloat(n)) && isFinite(n)}
      }

}

